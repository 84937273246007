import { Address } from "./AddressAutocomplete";

export default () => ({
    showStatesSelect: false,
    showStatesInput: false,
    states: [],
    stateLabel: null,
    stateSelectValue: "",
    countrySelectValue: "",
    init() {
        // TODO: Handle did you mean?
        this.updateStates();
        setTimeout(() => {
            this.updateStates();
            let el = this.$el.closest(".js-address-fieldset");
            new Address(el);
        }, 200);
    },

    toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    },

    updateStates() {
        const countryCode = this.countrySelectValue;
        if (
            window.states[countryCode] &&
            Object.keys(window.states[countryCode]).length
        ) {
            this.showStatesSelect = true;
            this.showStatesInput = false;
            const states = window.states[countryCode];
            this.states = Object.keys(states).map((key) => ({
                id: `${countryCode}-${key}`,
                code: key,
                name: states[key],
            }));
            // setTimeout(() => {
            //     this.stateSelectValue = "";
            // }, 10);
        } else {
            this.states = [];
            this.showStatesInput = true;
            this.showStatesSelect = false;
        }

        const stateLabel =
            window.stateTypes[countryCode] &&
            window.stateTypes[countryCode].length > 0
                ? window.stateTypes[countryCode][0].type
                : null;

        this.stateLabel = stateLabel ? this.toTitleCase(stateLabel) : null;
    },
});
