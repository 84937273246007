import { Api } from "../../services/Api";

export default () => ({
    addressModalOpen: false,
    addressSaving: false,
    container: null,
    init() {
        // TODO: Remove old Form button
        this.container = this.$el;
        var button = this.container.querySelector("form .submit-button");
        button.remove();
    },
    async save($dialog) {
        try {
            var form = this.container.querySelector("form");

            var data = new FormData(form);

            if (form.reportValidity) {
                form.reportValidity();
            }

            if (form.checkValidity && form.checkValidity() == false) {
                return;
            }

            this.addressSaving = true;

            var billingCountryCode = data.get("billingAddress[countryCode]");

            const domesticCountries = ["US", "CA"];

            // If international billing, update gatewayId to PayPal
            if (
                billingCountryCode &&
                domesticCountries.includes(billingCountryCode) == false
            ) {
                data.append("gatewayId", 2);
            }

            const res = await Api.post(
                "/actions/commerce/cart/update-cart",
                data,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );
            if (res.data.errors) {
                // TODO:
                this.addressSaving = false;
                alert("An error occurred updating address.");
                console.errors(res.data);
                return;
            }

            var container = document.querySelector(
                ".order-details .full-address"
            );

            const { cart } = res.data;

            const keys = {
                ".country": "countryCode",
                ".address-line1": "addressLine1",
                ".postal-code": "postalCode",
                ".locality": "locality",
                ".administrative-area": "administrativeArea",
                // TODO: Check stateValue
            };

            for (let key of Object.keys(keys)) {
                try {
                    var span = container.querySelector(key);
                    span.innerHTML = cart.shippingAddress[keys[key]];
                } catch (e) {}
            }
            // Just update the page so adjustments and available shipping options render
            window.location.reload();
        } catch (e) {
            console.error(e);
            this.addressSaving = false;
        }
    },

    openModal() {
        this.addressModalOpen = true;

        var addressModal = document.querySelector(
            ".modal-wrapper.address-modal-wrapper .modal"
        );
        if (addressModal) {
            addressModal.addEventListener("scroll", function () {
                document.activeElement.blur();
            });
        }

        setTimeout(() => {
            this.$dispatch("initAddressAutocomplete");
        }, 300);
    },
});
